import { gql, useQuery } from '@apollo/client';
import { cloneList } from '../../dashboards/utils';

const PHONE_COUNTRY_CODES_QUERY = gql`
  query PhoneCountryCodes {
    phoneCountryCodes
  }
`;

const usePhoneCountryCodes = () => {
  const { loading, data } = useQuery(PHONE_COUNTRY_CODES_QUERY);

  const phoneCountryCodes = cloneList(data?.phoneCountryCodes);
  const options = loading
    ? []
    : phoneCountryCodes.map((code: string) => ({
        value: code,
        label: `+${code}`,
      }));

  return [loading, options];
};

export default usePhoneCountryCodes;
